<template>
  <fieldset class="form-group border p-3">
    <legend class="w-auto px-2">{{ title }}</legend>
    <b-row v-if="certificate.certificate">
      <b-col md="6" class="mb-2">
        <b-form-group label="Fleet" label-for="fleet">
          <strong>{{ certificate.vehicle.name }}</strong>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group label="Name of Certificate" label-for="category">
          <strong>{{ certificate.certificate.name }}</strong>
        </b-form-group>
      </b-col>
<!--      <b-col md="6" class="mb-2">-->
<!--        <b-form-group label="Serial Number" label-for="category">-->
<!--          <strong>{{ certificate.serialNumber }}</strong>-->
<!--        </b-form-group>-->
<!--      </b-col>-->
<!--      <b-col md="6" class="mb-2">-->
<!--        <b-form-group label="Code" label-for="category">-->
<!--          <strong>{{ certificate.code }}</strong>-->
<!--        </b-form-group>-->
<!--      </b-col>-->
      <b-col md="6" class="mb-2">
        <b-form-group label="Type Certificate" label-for="type">
          <strong v-if="certificate.certificate.certificateType === 'PROVISIONAL'">PERMANENT (NO ENDORS)</strong>
          <strong v-else>{{
              certificate.certificate.certificateType.replace('_', ' ')
            }}</strong>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group label="Category Certificate" label-for="category">
          <strong>{{
            certificate.certificate.certificateCategory.name
          }}</strong>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group label="Issued By" label-for="place">
          <strong>{{
            certificate.issuedBy ? certificate.issuedBy.name : '-'
          }}</strong>
        </b-form-group>
      </b-col>
<!--      <b-col md="6" class="mb-2">-->
<!--        <b-form-group label="On Board" label-for="date_issued">-->
<!--          <strong>{{ certificate.notOnBoard ? 'No' : 'Yes' }}</strong>-->
<!--        </b-form-group>-->
<!--      </b-col>-->
      <b-col md="6" class="mb-2">
        <b-form-group label="Date Issued" label-for="date_issued">
          <strong>{{ certificate.dateIssued }}</strong>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group label="Place Issued" label-for="place">
          <strong>{{ certificate.placeIssued }}</strong>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group label="Verification Date" label-for="date_issued">
          <strong>{{
            certificate.verificationDate ? certificate.verificationDate : '-'
          }}</strong>
        </b-form-group>
      </b-col>
      <template>
      <b-col md="6" class="mb-2" v-if="certificate.certificate.certificateType !== 'PROVISIONAL'">
        <b-form-group label="Valid Until" label-for="date_issued">
          <strong>{{ certificate.expired ? certificate.expired : '-' }}</strong>
        </b-form-group>
      </b-col>
      </template>
      <b-col md="6" class="mb-2">
        <b-form-group label="Office Remarks" label-for="office_remarks">
          <strong>{{
            certificate.officeRemark ? certificate.officeRemark : '-'
          }}</strong>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group label="Ship Remarks" label-for="ship_remarks">
          <strong>{{
            certificate.shipRemark ? certificate.shipRemark : '-'
          }}</strong>
        </b-form-group>
      </b-col>
      <b-col md="12" v-if="certificate.file">
        <p>
          File Certificate: &nbsp;&nbsp;
          <a :href="certificate.file.url" target="_blank"
            v-b-tooltip.hover
            :title="certificate.file.fileName">
              <i class="fa fa-download"></i>
          </a>
        </p>
      </b-col>
    </b-row>
  </fieldset>
</template>

<script>
export default {
  name: 'DetailCertificate',
  props: {
    title: {
      type: String,
      default: 'Details:',
    },
    certificate: {
      type: Object,
      default: {},
    },
  },
}
</script>
