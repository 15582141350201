var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.certificate.certificate ? _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet",
      "label-for": "fleet"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.vehicle.name))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name of Certificate",
      "label-for": "category"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.certificate.name))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type Certificate",
      "label-for": "type"
    }
  }, [_vm.certificate.certificate.certificateType === 'PROVISIONAL' ? _c('strong', [_vm._v("PERMANENT (NO ENDORS)")]) : _c('strong', [_vm._v(_vm._s(_vm.certificate.certificate.certificateType.replace('_', ' ')))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Category Certificate",
      "label-for": "category"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.certificate.certificateCategory.name))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Issued By",
      "label-for": "place"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.issuedBy ? _vm.certificate.issuedBy.name : '-'))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date Issued",
      "label-for": "date_issued"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.dateIssued))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Place Issued",
      "label-for": "place"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.placeIssued))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Verification Date",
      "label-for": "date_issued"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.verificationDate ? _vm.certificate.verificationDate : '-'))])])], 1), [_vm.certificate.certificate.certificateType !== 'PROVISIONAL' ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Valid Until",
      "label-for": "date_issued"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.expired ? _vm.certificate.expired : '-'))])])], 1) : _vm._e()], _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Office Remarks",
      "label-for": "office_remarks"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.officeRemark ? _vm.certificate.officeRemark : '-'))])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ship Remarks",
      "label-for": "ship_remarks"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.certificate.shipRemark ? _vm.certificate.shipRemark : '-'))])])], 1), _vm.certificate.file ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('p', [_vm._v(" File Certificate:    "), _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": _vm.certificate.file.url,
      "target": "_blank",
      "title": _vm.certificate.file.fileName
    }
  }, [_c('i', {
    staticClass: "fa fa-download"
  })])])]) : _vm._e()], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }